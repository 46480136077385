import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout/layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import FormattedTitle from "../components/common/FormattedTitle"
import Pagination from "../components/common/Pagination"
import AnimationCircleWhite from "../components/animation/animation-circle-white"
import AnimationCirclePink from "../components/animation/animation-circle-pink"
import AnimationElement1 from "../components/animation/animation-element-1"
import AnimationElement2 from "../components/animation/animation-element-2"

const ArtistsIndex = ({ data, pageContext }) => (
  <Layout>
    <h1>
      <span className="purple-underline">Artists</span> We Support
    </h1>
    <div className="row py-5 position-relative" style={{ zIndex: "2" }}>
      {data.drupal.nodeQuery.entities.map((entity, index) => {
        const title = entity?.title || entity?.entityLabel || "Unknown"
        const thumbnail = getImage(entity?.thumbnail || {})
        const entityUrl = entity?.entityUrl?.path
        return (
          <div className="col col-12 col-md-3 align-self-start" key={index}>
            <div>
              <Link to={entityUrl}>
                <GatsbyImage
                  alt={title}
                  image={thumbnail}
                  imgClassName="preview"
                  loading="eager"
                  className="rounded-4"
                />
              </Link>
            </div>
            <h3 className="py-4">
              <Link to={entityUrl} className="white">
                <FormattedTitle>{title}</FormattedTitle>
              </Link>
            </h3>
          </div>
        )
      })}
    </div>
    <div className="py-4">
      <Pagination pageContext={{ ...pageContext, pagePrefix: "artists" }} />
    </div>
    <div className="py-4" style={{ position: "relative", zIndex: 1 }}>
      <Link to="/" className="white">
        Go back to the homepage
      </Link>
    </div>
    <AnimationCircleWhite
      style={{ top: "10%" }}
      className="animation animation-circle-white-1"
    />
    <AnimationCirclePink
      style={{ top: "80%" }}
      className="animation animation-circle-pink-1"
    />
    <AnimationElement1
      className="animation"
      style={{
        position: "absolute",
        top: "30%",
        left: "-150px",
        width: "60px",
      }}
    />
    <AnimationElement2
      className="animation"
      style={{
        top: "50%",
        position: "absolute",
        right: "-150px",
        transform: "scaleX(-1)",
        width: "120px",
      }}
    />
  </Layout>
)

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    drupal {
      nodeQuery(
        offset: $skip
        limit: $limit
        filter: {
          conditions: [
            { field: "type", operator: EQUAL, value: "musician" }
            { field: "status", operator: EQUAL, value: "1" }
          ]
          conjunction: AND
        }
      ) {
        entities {
          entityId
          entityLabel
          entityUrl {
            path
          }
          ... on Default_NodeMusician {
            body {
              processed
            }
            embedCode: fieldPlayerEmbedCode {
              value
            }
            title: fieldArtistName
            fieldSmallCircle {
              url
              id: targetId
            }
            fieldLargeRectangle {
              url
              id: targetId
            }
            thumbnail: gatsbySmallCircleImageFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  width: 400
                  height: 400
                )
              }
            }
            url: fieldUrl {
              value
            }
          }
        }
      }
    }
  }
`

export default ArtistsIndex
